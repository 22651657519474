<template>
  <div class="widget-wrapper">
    <div id="_bn_widget_" class="widget"></div>
  </div>
</template>

<script>
export default {
  name: "BookingWidget",
  props: {
    url: {
      type: String,
      default: '',
    }
  },
  mounted() {
    this.initWidget();
  },
  methods: {
    initWidget() {
      const script = document.createElement('script');
      script.src = '//widget.reservationsteps.ru/js/bnovo.js';
      script.onload = () => {
        if (typeof Bnovo_Widget !== 'undefined') {
          Bnovo_Widget.init(() => {
            Bnovo_Widget.open('_bn_widget_', {
              type: "horizontal",
              uid: "85917ed6-ced7-4340-a5fb-ad0a16e75485",
              lang: "ru",
              currency: "RUB",
              width: "100%",
              width_mobile: "300",
              background: "#ffffff",
              background_mobile: "#ffffff",
              bg_alpha: "100",
              bg_alpha_mobile: "100",
              border_color_mobile: "#C6CAD3",
              padding: "24",
              padding_mobile: "24",
              border_radius: "2",
              button_font_size: "14",
              button_height: "42",
              font_type: "roboto",
              without_title: "on",
              without_title_mobile: "on",
              title_color: "#242742",
              title_color_mobile: "#242742",
              title_size: "22",
              title_size_mobile: "22",
              inp_color: "#242742",
              inp_bordhover: "#FF7640",
              inp_bordcolor: "#DDDDDD",
              inp_alpha: "15",
              btn_background: "#FF7640",
              btn_background_over: "#FF7640",
              btn_textcolor: "#FFFFFF",
              btn_textover: "#FFFFFF",
              btn_bordcolor: "#FFFFFF",
              btn_bordhover: "#FF7640",
              min_age: "0",
              max_age: "17",
              adults_default: "2",
              dates_preset: "on",
              dfrom_tomorrow: "on",
              dto_custom: "on",
              dto_value: "2",
              promo: "on",
              cancel_color: "#1875F0",
              switch_mobiles: "on",
              switch_mobiles_width: "900",
              url: this.url,
            });
          });
        }
      };
      document.head.appendChild(script);
    },
  },
}
</script>

<style>
.widget {
  position: absolute !important;
}

.widget-wrapper {
  flex: 1;
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: center;
}
</style>
